import { useEffect, useMemo } from 'react';
import { useLocalStorageValue } from '@react-hookz/web';
import { useRouter } from 'next/router';
import Script from 'next/script';

import type { Locale } from '@/types/shared';

export type PageType = 'HOMEPAGE' | 'CATEGORY' | 'PRODUCT' | 'CART' | 'OTHER';
export interface RecommendationContext {
  locale?: Locale;
  type?: PageType;
  data?: string[];
}

interface ConsentEventPayload {
  hasConsentedBehavioural: boolean;
}

export const useActiveConsent = () => {
  const router = useRouter();

  const { value: consent_given, set: setConsent } = useLocalStorageValue(
    'userActiveConsent',
    { defaultValue: false },
  );

  useEffect(() => {
    const handleConsentChange = (event: CustomEvent<ConsentEventPayload>) => {
      const { hasConsentedBehavioural } = event.detail;
      window.updateConsentStatus(hasConsentedBehavioural);

      setConsent(hasConsentedBehavioural);
    };

    const handleConsentClosed = () => {
      router.reload();
    };

    window.addEventListener('cookieConsentChange', handleConsentChange);
    window.addEventListener('cookieConsentClosed', handleConsentClosed);

    return () => {
      window.removeEventListener('cookieConsentChange', handleConsentChange);
      window.removeEventListener('cookieConsentClosed', handleConsentClosed);
    };
  });

  useEffect(() => {
    window.DY.userActiveConsent = { accepted: consent_given };
    window.updateConsentStatus = (accepted) => {
      window.DY.userActiveConsent = { accepted };
      window.DYO?.ActiveConsent?.updateConsentAcceptedStatus(accepted);
    };
  }, [consent_given]);
};

export const getDYSectionId = () => {
  const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET;

  if (dataset.includes('prod')) return 9880147;
  if (process.env.NODE_ENV === 'development') return 9880229;

  return 9880019;
};

export interface DynamicYieldProps {
  contextPageType?: 'HOMEPAGE' | 'CATEGORY' | 'PRODUCT' | 'CART' | 'OTHER';
  contextDataType?:
    | 'B2B'
    | 'B2C'
    | 'Blog'
    | 'Community'
    | 'About'
    | 'CTAPage'
    | string;
}

export const DYRecommendationContext = ({
  dynamicYield,
}: {
  dynamicYield: DynamicYieldProps;
}) => {
  const { asPath, locale } = useRouter();

  const lang = locale?.replace('-', '_');
  const path = asPath;

  const recommendationContext = useMemo(
    () => ({
      type: dynamicYield?.contextPageType ?? 'OTHER',
      data: [dynamicYield?.contextDataType, path].filter(Boolean),
      lang,
    }),
    [dynamicYield, lang, path],
  );

  useEffect(() => {
    window.DY = window.DY || {};
    window.DY.recommendationContext = recommendationContext;
  }, [recommendationContext, lang]);

  return (
    <Script id="dy-recommendation-context" type="text/javascript">
      {`window.DY.recommendationContext = ${JSON.stringify(recommendationContext)};`}
    </Script>
  );
};
